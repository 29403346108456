<template>
<el-card id="board-opportunity-card">
    <div class="item-title">
        {{ item.contact.first_name }}
        {{ item.contact.last_name }}
    </div>
    <div class="item-phone">
        <img src="../../../assets/icon/call.svg" alt="" />
        {{ item.contact.cell_phone || "N/A" }}
    </div>
    <div class="item-email">
        <img src="../../../assets/icon/sms.svg" alt="" />
        {{ item.contact.email1 }}
    </div>
    <div class="details">
        <!-- <div class="item-toggle" @click="active = !active">
            <img v-if="active" src="../../../assets/icon/arrow-up.png" alt="" />
            <img v-else src="../../../assets/icon/arrow-down.png" alt="" />
        </div> -->
        <el-collapse @change="active = !active">
            <div class="all-details">
                <el-collapse-item :title='active ? "Hide details" : "Show details"'>
                    <div class="detail-item">
                        <b>Previous Rental:</b>
                        <span>
                            {{ item.previous_rental ? item.previous_rental : "None" }}
                        </span>
                    </div>
                    <div class="detail-item">
                        <b>Dates:</b>
                        <span v-if="item.arrival_date">
                            {{ item.arrival_date }}
                        </span>
                        <span v-if="item.departure_date">
                            <span v-if="item.arrival_date">To</span>
                            {{ item.departure_date }}
                        </span>
                        <span v-else> None provided </span>
                    </div>
                    <div class="detail-item">
                        <b>Guests:</b>
                        <span>
                            {{ item.guest > 1 ? item.guest : "None" }}
                            {{
                        item.children && item.children >= 0
                        ? "(" + item.children + "children )"
                        : ""
                    }}
                        </span>
                    </div>
                    <div class="detail-item">
                        <b>Notes:</b>
                        <span>{{ item.note }}</span>
                    </div>
            </el-collapse-item>
            </div>
        </el-collapse>
    </div>
</el-card>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: false,
            default: () => {
                return {
                    contact: {},
                };
            },
        },
    },
    data() {
        return {
            active: false
        };
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-collapse{
    border-top: 1px solid #fff !important;
    border-bottom: 1px solid #fff !important;
}
/deep/ .el-collapse-item__wrap{
    border: none;
}
/deep/ .el-collapse-item__header{
    border: none !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    font-family: "Manrope";
}
/deep/ .el-card {
    box-shadow: none !important;
    border: none !important;
}

#board-opportunity-card {
    margin: 10px 0px;
    text-align: left;
    cursor: pointer;

    .item-title {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #092a35;
    }

    .item-phone,
    .item-email {
        display: flex;
        align-items: center;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        color: #43555a;

        img {
            margin-right: 10px;
        }
    }

    .item-toggle {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        color: #092a35;

        img {
            position: absolute;
            left: 55%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .detail-item {
        margin: 8px 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #5a5a65;

        b {
            font-weight: 700;
            color: #4d4d4d;
        }
    }

    .details {
        .v-expansion-panels {
            box-shadow: none;

            .v-expansion-panel {
                .v-expansion-panel-header {
                    padding: 0px;
                }

                /deep/ .v-expansion-panel-content {
                    .v-expansion-panel-content__wrap {
                        padding: 0px !important;
                    }
                }
            }
        }
    }
}
</style>
